/* Variables */
$light-green: rgb(200, 255, 200);

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap');
/* Component Imports */
@import './links.scss';
@import './linkDiv.scss';
@import './footer.scss';
@import './imageZoomable.scss';
@import './buttonComponent.scss';
@import './inputComponent.scss';

/* Generic Styling */
::-webkit-scrollbar {
    width: 0.5rem;
    height: 0;
    background: transparent;
}
  ::-webkit-scrollbar-thumb {
    background: rgb(128, 128, 128);
    border-radius: 0.5em;
}

body {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 12pt;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

a {
    color: white;
    &:hover {
        color: $light-green;
    }
}

.App {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgb(31, 31, 31);
}

.page-constrained-width {
    width: calc(100% - 1rem);
    max-width: 1024px;
    margin-right: auto;
    margin-left: auto;
}

.center {
    text-align: center;
}