.section {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-shadow: inset 0 1.25rem 1rem -1rem rgb(0, 0, 0, 0.75);
    & > .section-art-credit {
        z-index: 2;
        position: absolute;
        font-size: 0.75em;
        color: inherit;
        right: 0.5rem;
        bottom: 0.5rem;
    }
    & > .section-content {
        position: relative;
        z-index: 1;
    }
    & > .section-background-image {
        box-shadow: inset 0 1.25rem 1rem -1rem rgb(0, 0, 0, 0.8);
        z-index: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

