.urza-page-title {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.ask-urza {
    min-height: 30rem;
}

.loyalty-abilities {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 18rem;
    margin-left: auto;
    margin-right: auto;
    gap: 2rem;
    margin-top: 3rem;
    & > button {
        font-size: 2rem;
        height: 3rem;
        width: 4rem;
    }
}

.activated-ability {
    font-size: 2rem;
    text-align: center;
    margin-top: 5rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: #000000dd;
    vertical-align: middle;
}