.printingToolPage {
    padding-bottom: 2rem;
}

.printingToolTitle {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 4rem;
}


.previewContainer {
    margin: 2rem 0;
    text-align: center;
    position: relative;
    height: 80vh;
    width: 100%;
    & > .preview {
        max-width: 100%;
        max-height: 100%;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
    }
    // & > .previewText {
    //     position: absolute;
    //     top: 26%;
    //     left: 26%;
    //     font-size: 10vh;
    //     color: black;
    //     font-weight: 900;
    //     opacity: 0.5;
    //     transform: rotate(-45deg);
    // }
}

.upload {
    margin: 2rem 0;
    width: 100%;
    & > * {
        width: 100% !important;
        margin: 0 auto;
    }
}

.pdfDownload {
    margin: 2rem 0;
    border: 2px solid red;
    width: 100%;
    height: 4rem;
}

.printingToolForm {
    padding: 0 2rem;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    & > * {
        grid-column: span 2;
    }
    & > .inputPair {
        grid-column: span 1;
    }
}

.printingToolCheckbox {
    padding: 0.25rem;
    & > input[type="checkbox"] {
        padding: 0.25rem;
        margin-left: 0.5rem;
        color: white;
        background-color: black;
        border: none;
        font-size: 1rem;
        cursor: pointer;
    }
    margin-bottom: 0.8rem;
}