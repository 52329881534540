$shadow-offset: 1rem;
$shadow-blur: 1rem;
$shadow-spread: -1rem;

.Footer {
    box-shadow: inset 0 $shadow-offset $shadow-blur $shadow-spread rgb(0, 0, 0, 0.5);
    padding-top: 4rem;
    padding-bottom: 4rem;
    width: 100vw;
    & > div {
        width: calc(100% - 1rem);
        max-width: 1024px;
        margin-right: auto;
        margin-left: auto;
    }
}