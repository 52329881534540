$size: 2.5rem;
$input-background-color: rgb(16, 16, 16);

.input-component {
	margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
}

$label-break-point: 60%;
.input-component-label {
    z-index: 11;
    background: linear-gradient(0deg, $input-background-color 0%, $input-background-color $label-break-point, rgba(0,0,0,0) $label-break-point, rgba(0,0,0,0) 100%);
    position: absolute;
    top: -0.4rem;
    left: 0.6rem;
    padding: 0 0.25rem;
	transition: transform 0.15s;
	-webkit-transition: transform 0.15s;
    font-size: 0.8rem;
    color: #eee;
}

.input-component-input {
    z-index: 10;
    color: white;
    width: calc(100% - 1.25rem);
	height: $size;
	border: 0.125rem solid white;
    border-radius: 0.125rem;
	background-color: $input-background-color;
    font-size: 1rem;
    outline: none;
    border: none;
    padding: 0 0.5rem;
	border: 0.125rem solid white;
    &:hover, &:focus {
        // background-color: rgb(20, 20, 20);
        border-color: $light-green;
        color: $light-green;
    }
}
select.input-component-input {
    width: 100%;
    cursor: pointer;
}